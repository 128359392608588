import { Component, HostListener, OnInit } from '@angular/core';

@Component({
	selector: 'app-protocols',
	templateUrl: './protocols.component.html',
	styleUrls: ['./protocols.component.css']
})
export class ProtocolsComponent implements OnInit {
	@HostListener('window:resize', ['$event']) onResize(event: Event) {
		this.checkIfMobile();
	}

	isMobile: boolean = false;
	isDesktop: boolean = false;
	pageOne: number = 1;
	pageOneLast: number = 1;
	pageSizeOne: number = 3;
	itemsOne: any[] = [
		{ name: '01' },
		{ name: '02' },
		{ name: '03' },
		{ name: '04' },
		{ name: '05' },
		{ name: '06' },
		{ name: '07' }
	];
	pageTwo: number = 1;
	pageTwoLast: number = 1;
	pageSizeTwo: number = 3;
	itemsTwo: any[] = [
		{ name: '010' },
		{ name: '020' },
		{ name: '025' },
		{ name: '030' },
		{ name: '035' },
		{ name: '040' },
		{ name: '050' },
		{ name: '060' },
		{ name: '070' },
		{ name: '080' },
		{ name: '090' },
		{ name: '100' },
		{ name: '110' },
		{ name: '120' },
		{ name: '130' },
		{ name: '135' },
		{ name: '140' },
		{ name: '150' },
		{ name: '160' },
		{ name: '170' },
		{ name: '180' },
		{ name: '190' },
		{ name: '191' },
		{ name: '200' },
		{ name: '210' },
		{ name: '301' },
		{ name: '302' },
		{ name: '303' },
		{ name: '304' },
		{ name: '305' },
		{ name: '306' },
		{ name: '307' }
	];
	pageThree: number = 1;
	pageThreeLast: number = 1;
	pageSizeThree: number = 3;
	itemsThree: any[] = [
	];
	pageFour: number = 1;
	pageFourLast: number = 1;
	pageSizeFour: number = 3;
	itemsFour: any[] = [
		{ name: '010' },
		{ name: '015' },
		{ name: '020' },
		{ name: '030' },
		{ name: '040' },
		{ name: '050' },
		{ name: '060' },
		{ name: '070' },
		{ name: '080' },
		{ name: '090' },
		{ name: '100' },
		{ name: '110' },
		{ name: '112' },
		{ name: '113' },
		{ name: '120' },
		{ name: '130' },
		{ name: '140' },
		{ name: '150' },
		{ name: '160' },
		{ name: '170' }
	];

	constructor() { }

	ngOnInit(): void {
		this.checkIfMobile();

		this.pageOneLast = Math.ceil(this.itemsOne.length / 3);
		this.pageTwoLast = Math.ceil(this.itemsTwo.length / 3);
		this.pageThreeLast = Math.ceil(this.itemsThree.length / 3);
		this.pageFourLast = Math.ceil(this.itemsFour.length / 3);
	}

	checkIfMobile() {
		this.isMobile = window.innerWidth <= 767.98;
		this.isDesktop = window.innerWidth >= 1400;

		if (this.isMobile) {
			this.pageSizeOne = 1;
			this.pageSizeTwo = 1;
			this.pageSizeThree = 1;
			this.pageSizeFour = 1;
		}
	}

	changePage(section: number, action: number) {
		switch (section) {
			case 1:
				if (action) {
					if (this.pageOne < this.pageOneLast) {
						this.pageOne++;
					}
				} else {
					if (this.pageOne !== 1) {
						this.pageOne--;
					}
				}
				break;
			case 2:
				if (action) {
					if (this.pageTwo < this.pageTwoLast) {
						this.pageTwo++;
					}
				} else {
					if (this.pageTwo !== 1) {
						this.pageTwo--;
					}
				}
				break;
			case 3:
				if (action) {
					if (this.pageThree < this.pageThreeLast) {
						this.pageThree++;
					}
				} else {
					if (this.pageThree !== 1) {
						this.pageThree--;
					}
				}
				break;
			case 4:
				if (action) {
					if (this.pageFour < this.pageFourLast) {
						this.pageFour++;
					}
				} else {
					if (this.pageFour !== 1) {
						this.pageFour--;
					}
				}
				break;
		}
	}
}
