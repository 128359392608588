import { DOCUMENT } from "@angular/common";
import { ChangeDetectorRef, Component, HostListener, Inject, OnInit } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";

import { User } from "./models/User";
import { BrandService } from "./services/brand.service";
import { CategoryService } from "./services/category.service";
import { MerchantService } from "./services/merchant.service";
import { UserService } from "./services/user.service";
import { UtilsService } from "./services/utils.service";
import { ModalsService } from './services/modals.service';
import { NewsletterComponent } from "./component/newsletter/newsletter.component";
import { CartService } from "./services/cart.service";
import {EventsService} from "./services/events.service";

@Component({
	selector: "app-root",
	templateUrl: "./app.component.html",
	styleUrls: ["./app.component.css"]
})
export class AppComponent implements OnInit {
	@HostListener("window:scroll", []) onWindowScroll() {
		this.scrolled = window.scrollY > 50;
		this.menuscrolled = window.scrollY > window.innerHeight * 0.75;
	}

	public user: User;
	public cartCount: any = false;
	public brands: any[][] = [[], [], [], []];
	public categories: any;
	public disableHover: boolean = false;
	public showSearch: boolean = false;
	public merchant: any;
	selectedBrand: any = null;
	selectedCategory: any = null;
	selectedSubCategory: any = null;
	loading: boolean = false;
	scrolled: boolean = false;
	menuscrolled: boolean = false;
	menuAboutUsIsCollapsed: boolean = true;
	menuProductsIsCollapsed: boolean = true;
	menuBrandsIsCollapsed: boolean = true;

	constructor(@Inject(DOCUMENT) private document: Document,
		public router: Router,
		private cartService: CartService,
		public brandService: BrandService,
		public categoryService: CategoryService,
		private cd: ChangeDetectorRef,
        private merchantService: MerchantService,
		public utilsService: UtilsService,
		public userService: UserService,
		public modalsService: ModalsService,
        public eventsService: EventsService
	) {
		this.loadCategories();
		this.loadBrands();
		this.loadMerchant();

        this.eventsService.subscribe('update:cart', (cart) => {
            this.cartCount = cart.quantity;
        });
	}

	async ngOnInit() {
		this.router.events.subscribe((event: any) => {
			this.loading = true;
			setTimeout(() => {
				this.loading = false;
			}, 300);
			this.disableHover = true;
			if (!(event instanceof NavigationEnd)) {
				setTimeout(() => {
					this.disableHover = false;
				}, 1000);
				return;
			}
			this.document.documentElement.style.scrollBehavior = "auto";
			setTimeout(() => window.scrollTo(0, 0), 5);
			setTimeout(() => (this.document.documentElement.style.scrollBehavior = "smooth"), 5);
			// this.disableHover = false;
			this.cd.detectChanges();
		});

		this.utilsService.loading().subscribe((data) => {
			this.loading = data;
		});

		this.userService.userObservable().subscribe((data) => {
			this.user = data;
		});

		const cart = this.cartService.getCart();
		this.cartCount = cart.quantity;
	}

	loadBrands() {
		this.brandService.all().subscribe((data: any) => {
			this.brands = this.spliceInto(data.data, 4);
		});
	}

	loadCategories() {
		this.categoryService.all().subscribe((data: any) => {
			this.categories = this.spliceInto(data.data, data.data.length);
			this.categories.forEach((category: any) => {
				category.forEach((cat: any) => {
					this.categoryService.subcategories(cat.id).subscribe((data: any) => {
						cat.subcategories = data.data;
					});
				});
			});
		});
	}

	loadSubcategories(category_id: string) {
		this.categoryService.subcategories(category_id).subscribe((data: any) => { });
	}

	loadMerchant() {
		this.merchantService.one().subscribe((data: any) => {
			this.merchant = data.data;
		});
	}

	spliceInto(items: any, n: number) {
		let res: any[][] = Array.from(Array(n), () => []);
		const wordsPerLine = Math.ceil(items.length / n);

		for (let line = 0; line < n; line++) {
			for (let i = 0; i < wordsPerLine; i++) {
				const value = items[i + line * wordsPerLine];
				if (!value) continue;
				res[line].push(value);
			}
		}

		return res;
	}

	switchSearch() {
		this.showSearch = !this.showSearch;
	}

	openModal() {
		this.modalsService.openModal(NewsletterComponent, false, 'modal-container');
	}
}
