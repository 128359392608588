import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { LoginGuard } from "./guards/login.guard";
import { AboutComponent } from "./pages/about/about.component";
import { BrandsComponent } from "./pages/brands/brands.component";
import { CheckoutComponent } from "./pages/checkout/checkout.component";
import { CodeConductComponent } from "./pages/code-conduct/code-conduct.component";
import { FavoritesComponent } from "./pages/favorites/favorites.component";
import { HistoryComponent } from "./pages/history/history.component";
import { HomeComponent } from "./pages/home/home.component";
import { HumanResourcesComponent } from "./pages/human-resources/human-resources.component";
import { LoginComponent } from "./pages/login/login.component";
import { NewsComponent } from "./pages/news/news.component";
import { OrdersComponent } from "./pages/orders/orders.component";
import { PaymentComponent } from "./pages/payment/payment.component";
import { PrivacyComponent } from "./pages/privacy/privacy.component";
import { ProductComponent } from "./pages/product/product.component";
import { ProductsComponent } from "./pages/products/products.component";
import { ProfileComponent } from "./pages/profile/profile.component";
import { ProtocolsComponent } from "./pages/protocols/protocols.component";
import { RegisterComponent } from "./pages/register/register.component";
import { SalesConditionComponent } from "./pages/sales-condition/sales-condition.component";
import { ServicesComponent } from "./pages/services/services.component";
import { StoreComponent } from "./pages/store/store.component";
import { StoresComponent } from "./pages/stores/stores.component";
import { SustainabilityComponent } from "./pages/sustainability/sustainability.component";
import { TermsConditionsComponent } from "./pages/terms-conditions/terms-conditions.component";
import { CustomizeComponent } from "./pages/customize/customize.component";
import { ChangePasswordComponent } from "./pages/change-password/change-password.component";
import { ChangeProfileComponent } from "./pages/change-profile/change-profile.component";
import { AddressComponent } from "./pages/address/address.component";
import { LoyaltyComponent } from "./pages/loyalty/loyalty.component";
import { FamilyComponent } from "./pages/family/family.component";
import { CampaignsComponent } from "./pages/campaigns/campaigns.component";
import { CampaignComponent } from "./pages/campaign/campaign.component";
import { TermsCustomerCardComponent } from "./pages/terms-customer-card/terms-customer-card.component";
import { NotLoginGuard } from "./guards/notLogin.guard";

const routes: Routes = [
	{ path: "", component: HomeComponent },
	{ path: "about", component: AboutComponent },
	{ path: "human-resources", component: HumanResourcesComponent },
	{ path: "code-conduct", component: CodeConductComponent },
	{ path: "privacy", component: PrivacyComponent },
	{ path: "sustainability", component: SustainabilityComponent },
	{ path: "protocols", component: ProtocolsComponent },
	{ path: "history", component: HistoryComponent },
	{ path: "terms-conditions", component: TermsConditionsComponent },
	{ path: "terms-customer-card", component: TermsCustomerCardComponent },
	{ path: "sales-condition", component: SalesConditionComponent },
	{ path: "campaigns", component: CampaignsComponent },
	{ path: "campaign/:id", component: CampaignComponent },
	{ path: "products", component: ProductsComponent },
	{ path: "product/:id", component: ProductComponent },
	{ path: "brands", component: BrandsComponent },
	{ path: "services", component: ServicesComponent },
	{ path: "customize", component: CustomizeComponent },
	{ path: "stores", component: StoresComponent },
	{ path: "agendar-consulta", component: StoresComponent },
	{ path: "store/:id", component: StoreComponent },
	{ path: "login", component: LoginComponent, canActivate: [NotLoginGuard] },
	{ path: "register", component: RegisterComponent, canActivate: [NotLoginGuard] },
	{ path: "checkout", component: CheckoutComponent, canActivate: [] },
	{ path: "payment/:id", component: PaymentComponent, canActivate: [LoginGuard] },
	{ path: "profile", component: ProfileComponent, canActivate: [LoginGuard] },
	{ path: "orders", component: OrdersComponent, canActivate: [LoginGuard] },
	{ path: "favorites", component: FavoritesComponent, canActivate: [LoginGuard] },
	{ path: "change-password", component: ChangePasswordComponent, canActivate: [LoginGuard] },
	{ path: "change-profile", component: ChangeProfileComponent, canActivate: [LoginGuard] },
	{ path: "address", component: AddressComponent, canActivate: [LoginGuard] },
	{ path: "loyalty", component: LoyaltyComponent, canActivate: [LoginGuard] },
	{ path: "family", component: FamilyComponent, canActivate: [LoginGuard] },
    { path: "news/:id/:slug", component: NewsComponent },
];

@NgModule({
	imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabledBlocking'
})],
	exports: [RouterModule],
	declarations: []
})
export class AppRoutingModule { }
