<section>
	<div class="container" *ngIf="!pageLoaded">
		<div class="box-loading">
			<div>
				<div class="p-5 lds-ellipsis">
					<div></div>
					<div></div>
					<div></div>
					<div></div>
				</div>
			</div>
		</div>
	</div>
	<div class="container" *ngIf="pageLoaded">
		<header style="background-image: url('{{ headerImage }}');">
			<h4 class="title text-center">{{ titlePage }}</h4>
		</header>

		<button class="btn btn-lg text-light to-top" (click)="scrollToTop()" title="Go to top"
			[ngClass]="{ 'show-scrollTop': windowScrolled }">
			<img src="./assets/icons/arrow-top.svg" width="64px" alt="" />
		</button>

		<div class="row">
			<div class="col-md-4 col-lg-3">
				<div class="sticky">
					<div class="scroller">
						<div *ngIf="isDesktop" class="row mb-5">
							<div class="col">
								<button class="btn btn-link btn-outline-secondary" (click)="changeGrid(4)">
									<img src="assets/icons/grid4.svg" alt="" class="img-fluid" />
								</button>
								<button class="btn btn-link btn-outline-secondary" (click)="changeGrid(2)">
									<img src="assets/icons/grid2.svg" alt="" class="img-fluid" />
								</button>
							</div>
						</div>
						<div class="accordion mb-5">
							<ngb-accordion [activeIds]="activeIds">
								<ngb-panel id="category" title="Categorias">
									<ng-template ngbPanelContent>
										<div class="form-check" *ngFor="let category of categoryOptions">
											<input class="form-check-input" type="checkbox"
												[(ngModel)]="category.checked" value="{{ category.id }}"
												id="category-{{ category.id }}" (change)="changeCategory($event)" />
											<label class="form-check-label text-light" for="category-{{ category.id }}">
												{{ category.name }}</label>
										</div>
									</ng-template>
								</ngb-panel>
								<ngb-panel *ngIf="subcategoryOptions && subcategoryOptions.length > 0" id="subcategory"
									title="Subcategorias">
									<ng-template ngbPanelContent>
										<div class="form-check" *ngFor="let subcategory of subcategoryOptions">
											<input class="form-check-input" type="checkbox"
												[(ngModel)]="subcategory.checked" value="{{ subcategory.id }}"
												id="subcategory-{{ subcategory.id }}"
												(change)="changeSubcategory($event)" />
											<label class="form-check-label text-light"
												for="subcategory-{{ subcategory.id }}"> {{ subcategory.name }}</label>
										</div>
									</ng-template>
								</ngb-panel>
								<ngb-panel *ngIf="brandOptions && brandOptions.length > 0" id="brand" title="Marcas">
									<ng-template ngbPanelContent>
										<div class="form-check" *ngFor="let brand of brandOptions">
											<input class="form-check-input" type="checkbox" [(ngModel)]="brand.checked"
												value="{{ brand.id }}" id="brand-{{ brand.id }}"
												(change)="changeBrand($event)" />
											<label class="form-check-label text-light" for="brand-{{ brand.id }}"> {{
												brand.name }}</label>
										</div>
									</ng-template>
								</ngb-panel>
								<ngb-panel id="price" title="Preços">
									<ng-template ngbPanelContent>
										<div class="form-check" *ngFor="let price of priceOptions">
											<input class="form-check-input" type="checkbox" [(ngModel)]="price.checked"
												value="{{ price.id }}" id="price-{{ price.id }}"
												(change)="changePrice($event)" />
											<label class="form-check-label text-light" for="price-{{ price.id }}"> {{
												price.name }}</label>
										</div>
									</ng-template>
								</ngb-panel>
							</ngb-accordion>
						</div>
					</div>
				</div>
			</div>
			<div class="col-md-8 col-lg-9">
				<div class="row">
					<div class="col">
						<div *ngIf="products === null" class="text-light text-center">
							<div class="p-5 lds-ellipsis">
								<div></div>
								<div></div>
								<div></div>
								<div></div>
							</div>
						</div>
						<div *ngIf="products?.length === 0" class="text-light text-center">
							<p class="text-lead text-light text-center">Nenhum produto encontrado</p>
						</div>
					</div>
				</div>

				<div *ngIf="products?.length" class="products row">
					<div *ngFor="let product of products" class="product col-sm-12 col-md-6 col-lg-3 mb-4"
						[ngClass]="[grid]">
                        <div class="col discount-badge" *ngIf="product.price_with_discount != 0 && product.discount">
                            <span class="badge badge-danger mt-2">{{ product.discount }} %</span>
                            <span class="badge badge-danger mt-2">Promoção</span>
                        </div>
						<a routerLink="/product/{{ product.id }}" class="link-product">
							<div class="image" style="background-image: url('{{ product.image }}');"></div>
						</a>
						<div class="detail">
							<a routerLink="/product/{{ product.id }}" class="link-product">
								<div class="name">
									<div>{{ product.brand.name }}
                                        <span *ngIf="product.brand.name"> | </span>
                                        {{ product.name }}
                                    </div>
                                    <div class="col" *ngIf="!product.discount">
                                        {{ (product.packs.length ? product.packs[0].price : product.price) | currency :
									"EUR" :
									"symbol" : "1.2-2" : "pt-PT" }}
                                    </div>
                                    <div class="col" *ngIf="product.price_with_discount != 0 && product.discount">
                                        <span class="discount-price">{{ product.price | currency : "EUR" : "symbol" : "1.2-2" : "pt-PT" }}</span>
                                        <span class="bigger-text"><b> {{ product.price_with_discount | currency : "EUR" : "symbol" : "1.2-2" : "pt-PT" }}</b></span>
                                    </div>
								</div>
							</a>
							<div class="like" (click)="$event.stopPropagation(); favorite(product)">
								<img src="assets/icons/like.svg" *ngIf="!product.favorite" height="20px" />
								<img src="assets/icons/like2.svg" *ngIf="product.favorite" height="20px" />
							</div>
						</div>
					</div>
				</div>

				<div *ngIf="products !== null && showLoadMore" class="text-center">
					<div *ngIf="page >= 0" class="btn btn-secondary" (click)="loadMore()">Ver mais</div>
				</div>
			</div>
		</div>
	</div>
</section>
