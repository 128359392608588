import {Component, OnInit} from '@angular/core';
import {StoreService} from 'src/app/services/store.service';
import {Router} from "@angular/router";

@Component({
	selector: 'app-stores',
	templateUrl: './stores.component.html',
	styleUrls: ['./stores.component.css']
})
export class StoresComponent implements OnInit {
	public stores: any;
    public apt: boolean = false;
    public href: string;

    constructor(private router: Router, private storeService: StoreService) {
        this.href = this.router.url;
    }

	ngOnInit(): void {
        if (this.href.includes("/agendar-consulta")) {
            this.apt = true;
        } else {
            this.all();
        }
	}

	all() {
		this.storeService.all().subscribe((data: any) => {
			this.stores = data.data;

			// move Guarda to first
			this.stores.sort(function (x: any, y: any) {
				return x.id == 225 ? -1 : y.id == 225 ? 1 : 0;
			});
		});
	}
}
