<section class="product">
	<div class="container breadcrumbs">
		<div class="row mb-2 text-light">
			<div class="col-auto">
				<a class="btn-link" [routerLink]="['/']">Início</a>
			</div>
			<div *ngIf="product?.category_id" class="col-auto">
				<span>/</span>
			</div>
			<div class="col-auto">
				<a class="btn-link" [routerLink]="['/products']">Produtos</a>
			</div>
			<div *ngIf="product?.category?.name" class="col-auto">
				<span>/</span>
			</div>
			<div *ngIf="product?.category?.name" class="col-auto">
				<a class="btn-link" [routerLink]="['/products']" [queryParams]="{ category_id: product?.category_id }">{{ product?.category?.name }}</a>
			</div>
			<div *ngIf="product?.subcategory?.name" class="col-auto">
				<span>/</span>
			</div>
			<div *ngIf="product?.subcategory?.name" class="col-auto">
				<a class="btn-link" [routerLink]="['/products']" [queryParams]="{ category_id: product?.category_id, subcategory_id: product?.subcategory_id }">{{ product?.subcategory?.name }}</a>
			</div>
			<div class="col-auto">
				<span>/</span>
			</div>
			<div class="col-auto">
				<span class="text-light">{{ product?.name }}</span>
			</div>
		</div>
	</div>
	<form [formGroup]="productForm">
		<div class="container">
			<!-- <h4 class="title text-center">{{ product?.name }}</h4> -->
			<div class="box p-3 p-md-5">
				<div class="row">
					<div class="col-12 col-md-7">
						<img class="img-fluid" src="{{ selectedImage ?? product?.images[0] }}" alt="" />

						<div class="row mt-5 justify-content-center">
							<ng-container *ngFor="let image of selectedImages">
								<div *ngIf="image" class="col-3" (click)="changeImage(image)">
									<img class="img-fluid" src="{{ image }}" alt="" />
								</div>
							</ng-container>
						</div>

						<div class="mt-5" [innerHTML]="product?.description"></div>
					</div>
					<div class="col-12 col-md-5">
						<div class="row mb-3">
							<div class="col">{{ product?.brand?.name }}</div>
						</div>
						<div class="row mb-3">
							<div class="col">
								<h4>{{ product?.name }}</h4>
								<small class="col">{{ product?.reference }}</small>
							</div>
						</div>
						<div class="row mb-3">
							<div class="col" *ngIf="product?.price != 0 && !product?.discount">
								<h5>{{ product?.price | currency : "EUR" : "symbol" : "1.2-2" : "pt-PT" }}</h5>
							</div>
							<div class="col" *ngIf="product?.price_with_discount != 0 && product?.discount">
								<h5>
                                    <span class="discount-price">{{ product?.price | currency : "EUR" : "symbol" : "1.2-2" : "pt-PT" }}</span>
                                    <span class="bigger-text"><b> {{ product?.price_with_discount | currency : "EUR" : "symbol" : "1.2-2" : "pt-PT" }}</b></span>
                                    <br />
                                    <span class="badge badge-danger mt-2">{{ product.discount }} %</span>
                                    <span class="badge badge-danger mt-2">Promoção</span>
                                </h5>
							</div>
						</div>
						<hr />
						<div class="row mb-3" *ngIf="selectedMeasures">
							<div class="col-auto">Tamanho:</div>
							<div class="col">
								{{ selectedMeasures }}
							</div>
						</div>
						<div class="row mb-3" *ngIf="selectedMaterial">
							<div class="col-auto">Material:</div>
							<div class="col">
								{{ selectedMaterial }}
							</div>
						</div>
						<div class="row mb-3" *ngIf="product?.typology">
							<div class="col-auto">Tipo de Lente:</div>
							<div class="col">
								{{ product?.typology }}
								<!-- <div class="btn btn-outline-secondary">
                                50-21-145
                            </div>
                            <div class="btn btn-outline-secondary">
                                50-21-145
                            </div>
                            <div class="btn btn-outline-secondary">
                                50-21-145
                            </div>
                            <div class="btn btn-outline-secondary">
                                50-21-145
                            </div>
                            <div class="btn btn-outline-secondary">
                                50-21-145
                            </div>
                            <div class="btn btn-outline-secondary">
                                50-21-145
                            </div> -->
							</div>
						</div>
						<div class="row mb-3" *ngIf="selectedColor">
							<div class="col-auto">Cor:</div>
							<div class="col">
								{{ selectedColor }}
								<!-- <div class="btn btn-outline-secondary">
                                50-21-145
                            </div>
                            <div class="btn btn-outline-secondary">
                                50-21-145
                            </div>
                            <div class="btn btn-outline-secondary">
                                50-21-145
                            </div> -->
							</div>
						</div>
						<div class="row mb-3" *ngIf="selectedColorLens">
							<div class="col-auto">Cor da Lente:</div>
							<div class="col">
								{{ selectedColorLens }}
							</div>
						</div>
						<div *ngIf="lensProduct">
							<div class="row mb-3">
								<div class="col-auto font-size-h4">Pack</div>
								<div class="col">
									<select formControlName="pack" class="form-control" placeholder="Selecione um pack">
										<option class="sl-placeholder" [ngValue]="null" [disabled]="true">Selecione
											uma opção</option>
										<option *ngFor="let pack of packs" [ngValue]="pack">
											{{ pack.title }}
										</option>
									</select>
								</div>
							</div>
							<div *ngIf="productForm.controls['pack'].value">
								<div class="row mb-3">
									<div class="col font-size-h4">Olhos</div>
									<div class="col-6">
										<select formControlName="eye" class="form-control" placeholder="Selecione uma opção">
											<option class="sl-placeholder" [ngValue]="null" [disabled]="true">Selecione
												uma opção</option>
											<option value="1">Ambos</option>
											<option value="2">Esquerdo</option>
											<option value="3">Direito</option>
										</select>
									</div>
								</div>
								<div class="row mb-3">
									<div class="col"></div>
									<div *ngIf="eye != 3" class="col-3 text-center">E</div>
									<div *ngIf="eye != 2" class="col-3 text-center">D</div>
								</div>
								<div class="row mb-3" *ngIf="curves.length">
									<div class="col font-size-h4">Curva Base</div>
									<div class="col-3" *ngIf="eye != 3">
										<select formControlName="curve" class="form-control" placeholder="">
											<option class="sl-placeholder" [ngValue]="null" [disabled]="true">Selecione
												uma opção</option>
											<option *ngFor="let curve of curves" [ngValue]="curve.value">
												{{ curve.value }}
											</option>
										</select>
									</div>
									<div class="col-3" *ngIf="eye != 2">
										<select formControlName="curve2" class="form-control" placeholder="">
											<option class="sl-placeholder" [ngValue]="null" [disabled]="true">Selecione
												uma opção</option>
											<option *ngFor="let curve of curves" [ngValue]="curve.value">
												{{ curve.value }}
											</option>
										</select>
									</div>
								</div>
								<div class="row mb-3" *ngIf="diameters.length">
									<div class="col font-size-h4">Diâmetro</div>
									<div class="col-3" *ngIf="eye != 3">
										<select formControlName="diameter" class="form-control" placeholder="">
											<option class="sl-placeholder" [ngValue]="null" [disabled]="true">Selecione
												uma opção</option>
											<option *ngFor="let diameter of diameters" [ngValue]="diameter.value">
												{{ diameter.value }}
											</option>
										</select>
									</div>
									<div class="col-3" *ngIf="eye != 2">
										<select formControlName="diameter2" class="form-control" placeholder="">
											<option class="sl-placeholder" [ngValue]="null" [disabled]="true">Selecione
												uma opção</option>
											<option *ngFor="let diameter of diameters" [ngValue]="diameter.value">
												{{ diameter.value }}
											</option>
										</select>
									</div>
								</div>
								<div class="row mb-3" *ngIf="balls.length">
									<div class="col font-size-h4">Potência Esférica</div>
									<div class="col-3" *ngIf="eye != 3">
										<select formControlName="ball" class="form-control" placeholder="">
											<option class="sl-placeholder" [ngValue]="null" [disabled]="true">Selecione
												uma opção</option>
											<option *ngFor="let ball of balls" [ngValue]="ball.value">
												{{ ball.value }}
											</option>
										</select>
									</div>
									<div class="col-3" *ngIf="eye != 2">
										<select formControlName="ball2" class="form-control" placeholder="">
											<option class="sl-placeholder" [ngValue]="null" [disabled]="true">Selecione
												uma opção</option>
											<option *ngFor="let ball of balls" [ngValue]="ball.value">
												{{ ball.value }}
											</option>
										</select>
									</div>
								</div>
								<div class="row mb-3" *ngIf="cylindricals.length">
									<div class="col font-size-h4">Potência Cilíndrica</div>
									<div class="col-3" *ngIf="eye != 3">
										<select formControlName="cylindrical" class="form-control" placeholder="">
											<option class="sl-placeholder" [ngValue]="null" [disabled]="true">Selecione
												uma opção</option>
											<option *ngFor="let cylindrical of cylindricals" [ngValue]="cylindrical.value">
												{{ cylindrical.value }}
											</option>
										</select>
									</div>
									<div class="col-3" *ngIf="eye != 2">
										<select formControlName="cylindrical2" class="form-control" placeholder="">
											<option class="sl-placeholder" [ngValue]="null" [disabled]="true">Selecione
												uma opção</option>
											<option *ngFor="let cylindrical of cylindricals" [ngValue]="cylindrical.value">
												{{ cylindrical.value }}
											</option>
										</select>
									</div>
								</div>
								<div class="row mb-3" *ngIf="axies.length">
									<div class="col font-size-h4">Eixo</div>
									<div class="col-3" *ngIf="eye != 3">
										<select formControlName="axle" class="form-control" placeholder="">
											<option class="sl-placeholder" [ngValue]="null" [disabled]="true">Selecione
												uma opção</option>
											<option *ngFor="let axl of axies" [ngValue]="axl.value">
												{{ axl.value }}
											</option>
										</select>
									</div>
									<div class="col-3" *ngIf="eye != 2">
										<select formControlName="axle2" class="form-control" placeholder="">
											<option class="sl-placeholder" [ngValue]="null" [disabled]="true">Selecione
												uma opção</option>
											<option *ngFor="let axl of axies" [ngValue]="axl.value">
												{{ axl.value }}
											</option>
										</select>
									</div>
								</div>
								<div class="row mb-3" *ngIf="addictions.length">
									<div class="col font-size-h4">Adição</div>
									<div class="col-3" *ngIf="eye != 3">
										<select formControlName="addiction" class="form-control" placeholder="">
											<option class="sl-placeholder" [ngValue]="null" [disabled]="true">Selecione
												uma opção</option>
											<option *ngFor="let addiction of addictions" [ngValue]="addiction.value">
												{{ addiction.value }}
											</option>
										</select>
									</div>
									<div class="col-3" *ngIf="eye != 2">
										<select formControlName="addiction2" class="form-control" placeholder="">
											<option class="sl-placeholder" [ngValue]="null" [disabled]="true">Selecione
												uma opção</option>
											<option *ngFor="let addiction of addictions" [ngValue]="addiction.value">
												{{ addiction.value }}
											</option>
										</select>
									</div>
								</div>
							</div>
						</div>

						<hr />

						<div class="row mb-3" *ngIf="product?.versions.length">
							<div class="col-auto font-size-h4">Versão</div>
							<div class="col">
								<select formControlName="version" class="form-control" placeholder="Selecione uma versão">
									<option class="sl-placeholder" [ngValue]="null" [disabled]="true">Selecione uma versão</option>
									<option *ngFor="let version of product.versions" [ngValue]="version.id">
										{{ version.color }}
									</option>
								</select>
							</div>
						</div>

						<div class="row mb-3">
							<div class="col">
								<button type="submit" [disabled]="formInvalid" class="btn btn-block w-100 btn-primary d-block" (click)="addCart()">Adicionar ao cesto</button>
							</div>
						</div>

						<hr />

						<div class="row mb-3">
							<div class="col">
								<div *ngIf="product?.favorite" class="btn d-block btn-outline-secondary" (click)="favorite(product)">Retirar dos Favoritos</div>
								<div *ngIf="!product?.favorite" class="btn d-block btn-outline-secondary" (click)="favorite(product)">Adicionar aos Favoritos</div>
							</div>
							<div class="col">
								<ngb-accordion #a="ngbAccordion" activeIds="custom-panel-1">
									<ngb-panel>
										<ng-template ngbPanelHeader let-opened="opened">
											<div class="accordion-button custom-header" [class.collapsed]="!opened">
												<button ngbPanelToggle class="btn d-block btn-outline-secondary">Partilhar</button>
											</div>
										</ng-template>
										<ng-template ngbPanelContent>
											<share-buttons theme="modern-dark" [include]="['copy', 'facebook', 'email', 'twitter', 'whatsapp']" [showIcon]="true" [showText]="false" [url]="shareUrl"> </share-buttons>
										</ng-template>
									</ngb-panel>
								</ngb-accordion>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</form>
</section>

<section>
	<div class="container">
		<h4 class="text-light text-left mb-5 text-uppercase">Produtos Relacionados</h4>

		<div class="products row">
			<div *ngFor="let product of product?.related.slice(0, 4)" class="product mb-4 col-sm">
				<a routerLink="/product/{{ product.id }}" class="link-product">
					<div class="image" style="background-image: url('{{ product.image }}');"></div>
				</a>
				<a routerLink="/product/{{ product.id }}" class="link-product">
					<div class="detail">
						<div class="name">
							<div>{{ product.brand.name }}<span *ngIf="product.brand.name"> | </span>{{ product.name }}</div>
							{{ product.price | currency : "EUR" : "symbol" : "1.2-2" : "pt-PT" }}
						</div>
						<div class="like" (click)="$event.stopPropagation(); favorite(product)">
							<img src="assets/icons/like.svg" *ngIf="!product.favorite" height="20px" />
							<img src="assets/icons/like2.svg" *ngIf="product.favorite" height="20px" />
						</div>
					</div>
				</a>
			</div>
		</div>
	</div>
</section>
