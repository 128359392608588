<div class="container px-0 px-md-5">
	<h3 class="title text-center">Acordos</h3>

	<p class="mt-5 mx-5">Na Ópticas Lince privilegiamos as parcerias de qualidade, seja nas marcas com quem trabalhamos, seja nos protocolos que celebramos. Aqui encontrará uma diversidade alargada de acordos com várias entidades que facilitarão as suas escolhas, porque, para nós, o seu conforto, não passa apenas pelo olhar! Temos acordos nacionais com as principais Entidades.</p>

	<h5 class="subtitle text-center">Seguradoras</h5>

	<div class="mx-4 mx-md-5 slider">
		<div class="gallery px-5">
			<div class="image" *ngFor="let item of itemsOne | slice : (pageOne - 1) * pageSizeOne : pageOne * pageSizeOne">
				<img src="assets/protocols/1/{{ item.name }}.png" />
			</div>

			<div class="btn-prev">
				<button class="btn btn-change-page" [disabled]="pageOne == 1" (click)="changePage(1, 0)">&lsaquo;</button>
			</div>
			<div class="btn-next">
				<button class="btn btn-change-page" [disabled]="pageOne == pageOneLast" (click)="changePage(1, 1)">&rsaquo;</button>
			</div>
		</div>
	</div>

	<h5 class="subtitle text-center">Instituições/Associações</h5>

	<div class="mx-4 mx-md-5 slider">
		<div class="gallery px-5">
			<div class="image" *ngFor="let item of itemsTwo | slice : (pageTwo - 1) * pageSizeTwo : pageTwo * pageSizeTwo">
				<img src="assets/protocols/2/{{ item.name }}.png" />
			</div>

			<div class="btn-prev">
				<button class="btn btn-change-page" [disabled]="pageTwo == 1" (click)="changePage(2, 0)">&lsaquo;</button>
			</div>
			<div class="btn-next">
				<button class="btn btn-change-page" [disabled]="pageTwo == pageTwoLast" (click)="changePage(2, 1)">&rsaquo;</button>
			</div>
		</div>
	</div>

	<h5 class="subtitle text-center">Empresas</h5>

	<div class="mx-4 mx-md-5 slider">
		<div class="gallery px-5">
			<div class="image" *ngFor="let item of itemsFour | slice : (pageFour - 1) * pageSizeFour : pageFour * pageSizeFour">
				<img src="assets/protocols/4/{{ item.name }}.png" />
			</div>

			<div class="btn-prev">
				<button class="btn btn-change-page" [disabled]="pageFour == 1" (click)="changePage(4, 0)">&lsaquo;</button>
			</div>
			<div class="btn-next">
				<button class="btn btn-change-page" [disabled]="pageFour == pageFourLast" (click)="changePage(4, 1)">&rsaquo;</button>
			</div>
		</div>
	</div>

	<h5 class="subtitle text-center">Para o melhor servir, também dispomos de acordos locais:</h5>

	<div class="mx-4 mx-md-5 accordion" id="guarda">
		<div class="accordion-item">
			<h2 class="accordion-header" id="headingguarda">
				<button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseguarda" aria-expanded="false" aria-controls="collapseguarda"><img src="assets/icons/plus.png" /><span class="mx-2">Guarda</span></button>
			</h2>
			<div id="collapseguarda" class="accordion-collapse collapse" aria-labelledby="headingguarda" data-bs-parent="#guarda">
				<div class="accordion-body">
					<ul class="list-unstyled">
						<li>ACADEMIA DE JUDO DA GUARDA</li>
						<li>ACAPO</li>
						<li>ALBINO TEIXEIRA</li>
						<li>ALDEIA SOS</li>
						<li>AMATOSCAR BEIRAS</li>
						<li>ASSOCIAÇÃO SEMPRE A APOIAR</li>
						<li>ASSOCIAÇÃO SOCIAL SÃO LÁZARO - VILA TOU</li>
						<li>ASSOCIAÇÃO ACADÉMICA GRD</li>
						<li>AUTICON</li>
						<li>BIGSOLAR</li>
						<li>B.V. GUARDA</li>
						<li>C.H. - PRONTO A VESTIR</li>
						<li>CÂMARA MUNICIPAL DA GUARDA</li>
						<li>CENTRO CULTURAL GUARDA</li>
						<li>CERCIG</li>
						<li>CLINIGUARDA</li>
						<li>CLUBE BEM ESTAR</li>
						<li>COFICAB</li>
						<li>COMUNILOG</li>
						<li>DESPERTAR DO SILÊNCIO</li>
						<li>DURA</li>
						<li>EGICLINICA</li>
						<li>ENSIGUARDA</li>
						<li>FINICLASSE</li>
						<li>GRUPO PINHEIRO</li>
						<li>GUARDA ESCOLA</li>
						<li>IDEIAS-GUARDA</li>
						<li>INATEL</li>
						<li>IPG - SERVIÇOS SOCIAIS</li>
						<li>IPG - GUARDA</li>
						<li>IURIS</li>
						<li>J. F. CASTANHEIRA</li>
						<li>LUBRIPOR</li>
						<li>NATURA EMPREENDIMENTOS</li>
						<li>OPUALTE</li>
						<li>PARTIDAS E CHEGADAS</li>
						<li>RITUS MODA</li>
						<li>S.C. MISERICORDIA GUARDA</li>
						<li>SODECIA - POWERTRAIN</li>
						<li>STADIUS FITNESS CLUB</li>
						<li>SUPERGUARDA</li>
						<li>TRASPORTES BERNARDO MARQUES</li>
					</ul>
				</div>
			</div>
		</div>

		<div class="accordion-item">
			<h2 class="accordion-header" id="headingbatalha">
				<button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapsebatalha" aria-expanded="false" aria-controls="collapsebatalha"><img src="assets/icons/plus.png" /><span class="mx-2">Batalha</span></button>
			</h2>
			<div id="collapsebatalha" class="accordion-collapse collapse" aria-labelledby="headingbatalha" data-bs-parent="#batalha">
				<div class="accordion-body">
					<ul class="list-unstyled">
						<li>ASSOCIAÇÃO DE PAIS DAS ESCOLAS DA BATALHA AGRUPAMENTO DE ESCOLAS DA BATALHA</li>
						<li>CARBOMIN S.A.</li>
						<li>CONSTRUÇÕES PRAGOSA S.A.</li>
						<li>ECO IMPACT S.A.</li>
						<li>EUROSOL HOTELS</li>
						<li>FAPOR - FAIANÇAS PORTUGAL</li>
						<li>GRUPO PRAGOSA: CONCRETOPE - FÁBRICA DE BETÃO PRONTO S.A.</li>
						<li>HOTEL LIS BATALHA</li>
						<li>PRAGOSA - SGPS S.A.</li>
						<li>PRAGOSA - TRANSPORTES S.A.</li>
						<li>PRAGOSA BETÃO S.A.</li>
						<li>PRAGOSA INDÚSTRIA EXTRACTIVA S.A.</li>
						<li>PRAGOSA IMOBILIÁRIA S.A.</li>
						<li>SOBRISSUL-SOCIEDADE DE BRITAS SELECCIONADAS DO SUL S.A.</li>
					</ul>
				</div>
			</div>
		</div>

		<div class="accordion-item">
			<h2 class="accordion-header" id="headingbelmonte">
				<button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapsebelmonte" aria-expanded="false" aria-controls="collapsebelmonte"><img src="assets/icons/plus.png" /><span class="mx-2">Belmonte</span></button>
			</h2>
			<div id="collapsebelmonte" class="accordion-collapse collapse" aria-labelledby="headingbelmonte" data-bs-parent="#belmonte">
				<div class="accordion-body">
					<ul class="list-unstyled">
						<li>A.P.D. CASTELO BRANCO</li>
						<li>AGRUPAMENTO ESCOLAS BELMONTE</li>
						<li>ASSOCIAÇÃO AMIGOS DA ESTAÇÃO DE BELMONTE</li>
						<li>B.V. BELMONTE</li>
						<li>CASA DO PESSOAL CM BELMONTE</li>
						<li>CASAS DE SANTIAGO</li>
						<li>CENTRO DE CULTURA BELMONTE</li>
						<li>CENTUM CELLAS</li>
						<li>EMPDS</li>
						<li>GRASIL</li>
						<li>LANIFATO</li>
						<li>LIGA COMBATENTES BELMONTE</li>
						<li>MUNICÍPIO DE BELMONTE</li>
						<li>S. C. MISERICÓRDIA BELMONTE</li>
						<li>TORFAL</li>
						<li>TORRE</li>
					</ul>
				</div>
			</div>
		</div>

		<div class="accordion-item">
			<h2 class="accordion-header" id="headingceloricodabeira">
				<button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseceloricodabeira" aria-expanded="false" aria-controls="collapseceloricodabeira"><img src="assets/icons/plus.png" /><span class="mx-2">Celorico da Beira</span></button>
			</h2>
			<div id="collapseceloricodabeira" class="accordion-collapse collapse" aria-labelledby="headingceloricodabeira" data-bs-parent="#celoricodabeira">
				<div class="accordion-body">
					<ul class="list-unstyled">
						<li>A.S.S.M. FORNOTELHEIRO</li>
						<li>B. V. CELORICO DA BEIRA</li>
						<li>DISTRIBEIRA, SUPERMERCADOS</li>
						<li>LIGA DOS NATURAIS E AMIGOS DE PRADO</li>
						<li>MEY TEXTEIS</li>
						<li>S. C. MISERICÓRDIA CELORICO</li>
						<li>SPORTING CLUBE</li>
					</ul>
				</div>
			</div>
		</div>

		<div class="accordion-item">
			<h2 class="accordion-header" id="headingcovilha">
				<button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapsecovilha" aria-expanded="false" aria-controls="collapsecovilha"><img src="assets/icons/plus.png" /><span class="mx-2">Covilhã</span></button>
			</h2>
			<div id="collapsecovilha" class="accordion-collapse collapse" aria-labelledby="headingcovilha" data-bs-parent="#covilha">
				<div class="accordion-body">
					<ul class="list-unstyled">
						<li>ADC - ÁGUAS DA COVILHÃ</li>
						<li>ALBI ASTA - ASSOCIAÇÃO DE TEATRO</li>
						<li>ARTUR GAIOLA TRANSPORTES</li>
						<li>ASSO. DIABETES SERRA ESTRELA</li>
						<li>B.V. COVILHÃ</li>
						<li>BANDA DA COVILHÃ</li>
						<li>CAMARA MUNICIPAL DA COVILHÃ</li>
						<li>CARTÃO IRMÃO MISERICÓRDIA COVILHÃ</li>
						<li>CASA DO PESSOAL HOSPITAL COVA BEIRA</li>
						<li>CCD ORIENTAL SÃO MARTINHO CV</li>
						<li>CENTRO APOIO DE CORTES</li>
						<li>CLUBE DO PROFESSOR DA COVILHÃ</li>
						<li>CLUBE UNIÃO DA COVIHÃ</li>
						<li>DESERTUNA</li>
						<li>FUNDAÇÃO IMACULADA CONCEIÇÃO</li>
						<li>GRUPO DESPORTIVO ANIMAÇÃO CULTURAL DA BOUÇA</li>
						<li>GRUPO DESPORTIVO MATA</li>
						<li>GRUPO INSTRUÇÃO E RECREIO DO RODRIGO</li>
						<li>ITCENTER/SHAR</li>
						<li>LEÕES DA FLORESTA</li>
						<li>LIGA DOS AMIGOS B. P. ALTOS</li>
						<li>MOTO CLUBE DA COVILHÃ</li>
						<li>MUTUALISTA COVILHANENSE</li>
						<li>NÚCLEO DE ESTUDANTES DE MEDICINA DA UNIVERSIDADE DA BEIRA INTERIOR</li>
						<li>PENTA CLUBE COVILHÃ</li>
						<li>RESIDÊNCIA SENIOR DON ANTONIO</li>
						<li>ROPRE</li>
						<li>SBSI -SINDICATO BANCÁRIO SUL</li>
						<li>SERRAGEL</li>
						<li>SINDICATO TEXTIL BEIRA BAIXA</li>
						<li>TWINTEX</li>
						<li>UBI - NÚCLEO ESTUDANTES OPTOMETRIA</li>
						<li>UBIPHARMA - NÚCLEO DE ESTUDANTES DE CIÊNCIAS FARMACÊUTICAS DA UNIVERSIDADE DA BEIRA INTERIOR</li>
					</ul>
				</div>
			</div>
		</div>

		<div class="accordion-item">
			<h2 class="accordion-header" id="headinggouveia">
				<button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapsegouveia" aria-expanded="false" aria-controls="collapsegouveia"><img src="assets/icons/plus.png" /><span class="mx-2">Gouveia</span></button>
			</h2>
			<div id="collapsegouveia" class="accordion-collapse collapse" aria-labelledby="headinggouveia" data-bs-parent="#gouveia">
				<div class="accordion-body">
					<ul class="list-unstyled">
						<li>BOMBEIROS VILA NOVA DE TAZEM</li>
						<li>BOMBEIROS VOLUNTÁRIOS DE GOUVEIA</li>
						<li>COSTUMES DO HORIZONTE/MUSEU DA AVÓ</li>
						<li>EUROSOL HOTELS</li>
						<li>REENCONTRO ASSOCIAÇÃO</li>
						<li>S.C.MISERICORDIA GOUVEIA</li>
						<li>TEXTEIS SOUSA S. GOUVEIA</li>
					</ul>
				</div>
			</div>
		</div>

		<div class="accordion-item">
			<h2 class="accordion-header" id="headingmatosinhos">
				<button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapsematosinhos" aria-expanded="false" aria-controls="collapsematosinhos"><img src="assets/icons/plus.png" /><span class="mx-2">Matosinhos</span></button>
			</h2>
			<div id="collapsematosinhos" class="accordion-collapse collapse" aria-labelledby="headingmatosinhos" data-bs-parent="#matosinhos">
				<div class="accordion-body">
					<ul class="list-unstyled">
						<li>ASSOCIAÇÃO DE PAIS E ENCARREGADOS DE EDUCAÇÃO DA ESCOLA BÁSICA DE MATOSINHOS</li>
						<li>AUTO SUECO, LDA.</li>
						<li>AZEVEDOS SGPS</li>
						<li>CARTÃO MATOSINHOS JOVEM</li>
						<li>CCD MATOSINHOS</li>
						<li>CLAVE DE SOL RESIDÊNCIA SENIOR</li>
						<li>CLUBE GALP - DELEGAÇÃO NORTE</li>
						<li>ESCOLA DE BALLET PORTO</li>
						<li>GDC COHAEMATO</li>
						<li>GRUPO DRAMÁTICO E MUSICAL FLOR DE INFESTA</li>
						<li>SOARES DA COSTA S.A.</li>
						<li>UNIÃO FREG. SÃO MAMEDE E SRA HORA</li>
					</ul>
				</div>
			</div>
		</div>

		<div class="accordion-item">
			<h2 class="accordion-header" id="headingoliveiradohospital">
				<button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseoliveiradohospital" aria-expanded="false" aria-controls="collapseoliveiradohospital"><img src="assets/icons/plus.png" /><span class="mx-2">Oliveira do Hospital</span></button>
			</h2>
			<div id="collapseoliveiradohospital" class="accordion-collapse collapse" aria-labelledby="headingoliveiradohospital" data-bs-parent="#oliveiradohospital">
				<div class="accordion-body">
					<ul class="list-unstyled">
						<li>ACORFATO INDÚSTRIA CONFEÇÕES</li>
						<li>ALVA VALLEY HOTEL</li>
						<li>B. V. OLIVEIRA HOSPITAL</li>
						<li>CÂMARA MUNICIPAL OLIVEIRA HOSPITAL</li>
						<li>CONSTRUÇÕES ARMINDO OLIVEIRA</li>
						<li>DAVION - INDÚSTRIA VESTUARIO</li>
						<li>S. C. MISERICÓRDIA GALIZES</li>
					</ul>
				</div>
			</div>
		</div>

		<div class="accordion-item">
			<h2 class="accordion-header" id="headingourem">
				<button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseourem" aria-expanded="false" aria-controls="collapseourem"><img src="assets/icons/plus.png" /><span class="mx-2">Ourém</span></button>
			</h2>
			<div id="collapseourem" class="accordion-collapse collapse" aria-labelledby="headingourem" data-bs-parent="#ourem">
				<div class="accordion-body">
					<ul class="list-unstyled">
						<li>TECNORÉM</li>
					</ul>
				</div>
			</div>
		</div>

		<div class="accordion-item">
			<h2 class="accordion-header" id="headingpinhel">
				<button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapsepinhel" aria-expanded="false" aria-controls="collapsepinhel"><img src="assets/icons/plus.png" /><span class="mx-2">Pinhel</span></button>
			</h2>
			<div id="collapsepinhel" class="accordion-collapse collapse" aria-labelledby="headingpinhel" data-bs-parent="#pinhel">
				<div class="accordion-body">
					<ul class="list-unstyled">
						<li>ACDS DO VALDEIME</li>
						<li>BIOSFERA</li>
						<li>B.V. PINHEL</li>
						<li>CASA APOIO SOCIAL DE PALA</li>
						<li>CENTRO SOCIAL PARÓQUIA FREIXEDAS</li>
						<li>DISTRIPINHEL</li>
						<li>F.M.P.-FLY MECANICA PRECISÃO</li>
						<li>GRANIPOLY, LDA.</li>
						<li>GRUPO BIU</li>
						<li>S. C. MISERICÓRDIA</li>
						<li>S.S.C.D.C.M. PINHEL</li>
						<li>SUPERMEDA, SUPERMERCADOS</li>
					</ul>
				</div>
			</div>
		</div>

		<div class="accordion-item">
			<h2 class="accordion-header" id="headingsabugal">
				<button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapsesabugal" aria-expanded="false" aria-controls="collapsesabugal"><img src="assets/icons/plus.png" /><span class="mx-2">Sabugal</span></button>
			</h2>
			<div id="collapsesabugal" class="accordion-collapse collapse" aria-labelledby="headingsabugal" data-bs-parent="#sabugal">
				<div class="accordion-body">
					<ul class="list-unstyled">
						<li>A.M.FOIOS</li>
						<li>ADES</li>
						<li>AGRUPAMENTO ESCOLAS SABUGAL</li>
						<li>ASCD REBOLOSA</li>
						<li>ASSOCIAÇÃO AMIGOS DE PEVA ALMEIDA</li>
						<li>ASSOCIAÇÃO AMIGOS ALDEIA DA PONTE</li>
						<li>B. V. ALMEIDA</li>
						<li>B.V. SABUGAL</li>
						<li>CENTRO SOCIAL LAGEOSA DA RAIA</li>
						<li>CASA DO POVO ALDEIA VELHA</li>
						<li>CENTRO SOCIAL ALDEIA DO BISPO</li>
						<li>CENTRO SOCIAL QUADRAZAIS</li>
						<li>CENTRO SOCIAL RAPOULA</li>
						<li>DISTRISABUGAL, SUPERMERCADOS</li>
						<li>FUNDAÇÃO PADRE JOSÉ MIGUEL</li>
						<li>MODACHE</li>
						<li>MUNICÍPIO SABUGAL</li>
						<li>OLIPAL</li>
						<li>S. C. MISERICORDIA ALMEIDA</li>
						<li>S. C. MISERICORDIA SABUGAL</li>
						<li>UNIVESTE</li>
						<li>VIÚVA MONTEIRO</li>
					</ul>
				</div>
			</div>
		</div>

		<div class="accordion-item">
			<h2 class="accordion-header" id="headingseia">
				<button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseseia" aria-expanded="false" aria-controls="collapseseia"><img src="assets/icons/plus.png" /><span class="mx-2">Seia</span></button>
			</h2>
			<div id="collapseseia" class="accordion-collapse collapse" aria-labelledby="headingseia" data-bs-parent="#seia">
				<div class="accordion-body">
					<ul class="list-unstyled">
						<li>ARA SHOES</li>
						<li>ASSOCIAÇÃO HUMANITÁRIA PARANHOS</li>
						<li>B.V. SEIA</li>
						<li>CÂMARA MUNICIPAL DE SEIA</li>
						<li>CONFRARIA BROA E BOLO LORIGA</li>
						<li>EUROSOL HOTELS</li>
						<li>FUNDAÇÃO AURORA R. C. B SEIA</li>
						<li>FUNDAÇÃO CARDOSO MOURA</li>
						<li>H. SARA TRADING</li>
						<li>LAHNSAS SEIA</li>
						<li>MUSEU DO PÃO</li>
						<li>S. C. MISERICORDIA SEIA</li>
						<li>SEIA FUTEBOL CLUB</li>
						<li>TEXAMIRA SEIA</li>
						<li>UNIÃO FREG. SEIA, ROMÃO E LAPA</li>
					</ul>
				</div>
			</div>
		</div>

		<div class="accordion-item">
			<h2 class="accordion-header" id="headingtrancoso">
				<button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapsetrancoso" aria-expanded="false" aria-controls="collapsetrancoso"><img src="assets/icons/plus.png" /><span class="mx-2">Trancoso</span></button>
			</h2>
			<div id="collapsetrancoso" class="accordion-collapse collapse" aria-labelledby="headingtrancoso" data-bs-parent="#trancoso">
				<div class="accordion-body">
					<ul class="list-unstyled">
						<li>CASA DA PRISCA</li>
						<li>DISTRITRANCOSO, SUPERMERCADOS</li>
						<li>GRUPO DESPORTIVO TRANCOSO</li>
						<li>SANTA CASA DA MISERICÓRDIA DE TRANCOSO</li>
						<li>SUPERMEDA, SUPERMERCADOS</li>
					</ul>
				</div>
			</div>
		</div>

		<div class="accordion-item">
			<h2 class="accordion-header" id="headingvilanovadefozcoa">
				<button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapsevilanovadefozcoa" aria-expanded="false" aria-controls="collapsevilanovadefozcoa"><img src="assets/icons/plus.png" /><span class="mx-2">Vila Nova de Foz Côa</span></button>
			</h2>
			<div id="collapsevilanovadefozcoa" class="accordion-collapse collapse" aria-labelledby="headingvilanovadefozcoa" data-bs-parent="#vilanovadefozcoa">
				<div class="accordion-body">
					<ul class="list-unstyled">
						<li>ASSOCIAÇÃO DE JOVENS AMIGOS DO ARNOZÊLO</li>
						<li>CENTRO DE APOIO SOCIAL V. N. F. CÔA</li>
						<li>CENTRO SOCIAL DE CASTELO MELHOR</li>
						<li>CÔA PARQUE</li>
						<li>INTERMARCHÉ VN FOZ COA/SUPERCÔA</li>
						<li>S. C. MISERICÓRDIA FOZ CÔA</li>
						<li>SUPERMEDA, SUPERMERCADOS</li>
					</ul>
				</div>
			</div>
		</div>
	</div>
</div>

<app-appointment></app-appointment>
